import React, { Component } from 'react'
import { Section, Title, SectionButton } from '../../utils'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { styles } from '../../utils'

export default class QuickInfo extends Component {
  render() {
    return (
      <Section>
        <Title message="let us tell you" title="our mission" />
        <QuickInfoWrapper>
          <p className="text">
            Bringing people together to work on challenging and interesting projects.
            Managing and supporting our teams. Provide training where needed.
            Developing our projects with the ultimate goal of creating new products and services.
            Exploring new technologies and generating new business ideas.
          </p>
          <Link to="/projects/" style={{ textDecoration: 'none' }}>
            <SectionButton style={{ margin: '2rem auto' }}>select your project now</SectionButton>
          </Link>
        </QuickInfoWrapper>
      </Section>
    )
  }
}

const QuickInfoWrapper = styled.div`
width:90%;
margin:2rem auto;
.text{
  line-height:2rem;
  color:${styles.colors.mainGrey};
  word-spacing:0.2rem;
}
@media (min-width:768px){
  width:70%;
}
@media (min-width:992px){
  width:60%;
}
`
